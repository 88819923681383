import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const LasersPage = function () {
  return (
    <Layout>
      <Seo
        title="Laser Treatments"
        description="Find out the benefits and advantages of laser treaments"
      />

      <div className="relative h-32 bg-gray-900 md:h-28 lg:h-36" />
      <div className="relative z-20  mx-auto max-w-md px-4 py-16 sm:max-w-4xl sm:px-6  lg:max-w-6xl lg:px-8">
        <h1 className="content-page-title pb-10">
          Sharplight Laser Treatments
        </h1>
        <div className=" md:grid md:grid-cols-4 md:gap-10">
          <div>
            <h2 className="content-page-title">Skin Rejuvenation</h2>
          </div>
          <div className="md:col-span-3">
            <p className="content-page-text">
              The skin rejuvenation treatments, apply intense pulsed light (IPL)
              radio frequency (RF) and infrared technologies (IR), result in
              clearer, firmer, younger looking skin in just a few sessions. This
              treatment is suitable for the face, hands, neck & chest of all
              skin types & age groups. The treatments improve the appearance of
              fine lines, sunspots, enlarged pores, elastosis, mottled
              pigmentation, dyschromia, rosacea, broken capillaries, spider
              veins amongst others.
            </p>
            <div className="my-4  font-raleway font-bold uppercase">
              <span className="border-b-2 border-brand-primary text-gray-600">
                Advantages
              </span>
            </div>
            <ul className="content-page-text list-disc pl-4">
              <li>
                The treatments efficiently & effectively tighten skin and reduce
                a range of aging signs, like wrinkles & pigmentation
              </li>
              <li>
                Long lasting results are achieved in relatively small number of
                treatments
              </li>
              <li>Treatments are non-invasive, clean & relatively painless</li>
              <li>Treatments are once every 4 weeks</li>
            </ul>
          </div>
        </div>
        <hr className="my-10" />
        <div className=" md:grid md:grid-cols-4 md:gap-10">
          <div>
            <h2 className="content-page-title">Laser Hair Reduction</h2>
          </div>
          <div className="md:col-span-3">
            <p className="content-page-text">
              The Sharplight laser hair reduction System is considered to be of
              the highest quality & is one of the most advanced laser hair
              reduction system. A light beam penetrates deep into the skin and
              is absorbed by the melanin pigment in the hair follicle. This
              energy is powerful enough to penetrate the hair follicles and
              prevent future growth, without damaging the surrounding skin..
            </p>
            <div className="my-4  font-raleway font-bold uppercase">
              <span className="border-b-2 border-brand-primary text-gray-600">
                Advantages
              </span>
            </div>
            <ul className="content-page-text list-disc pl-4">
              <li>Treatments are non invasive & relatively painless</li>
              <li>80-90% hair reduction after 6-8 treatments</li>
              <li>No downtime</li>
            </ul>
          </div>
        </div>
        <hr className="my-10" />
        <div className=" md:grid md:grid-cols-4 md:gap-10">
          <div>
            <h2 className="content-page-title">Body/face contouring</h2>
          </div>
          <div className="md:col-span-3">
            <p className="content-page-text">
              Congratulations you’ve lost some weight! Now what to do with the
              loose skin....we can help with that! Sharplight’s body contouring
              will restore your confidence and get you beach ready! A session
              can last up to 90 minutes, depending on the area. The radio
              frequency (RF) target the fat cells causing them to break down,
              absorbed by the lymphatic system and naturally disposed of through
              the body’s natural elimination process. Reducing fats from your
              body will result in losses of inches around the area that’s been
              treated. Once the desired reduction is achieved, the infrared
              light (IF) is applied to renew the collagen cells. Maintaining a
              healthy lifestyle in important to achieve maximum results. Popular
              areas are the arms, chin, abdomen & buttocks
            </p>
            <div className="my-4  font-raleway font-bold uppercase">
              <span className="border-b-2 border-brand-primary text-gray-600">
                Advantages
              </span>
            </div>
            <ul className="content-page-text list-disc pl-4">
              <li>Clients will see the results after 1 to 4 sessions</li>
              <li> Improves the function of your lymphatic system</li>
              <li>Reduces the appearance of blemishes cause be cellulite</li>
              <li>
                You can target more than one area of your body at one time
              </li>
              <li> Pain free- feels like a warm massage</li>
              <li>No down time</li>
            </ul>
          </div>
        </div>
        <hr className="my-10" />
      </div>
    </Layout>
  );
};

export default LasersPage;
